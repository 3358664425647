import React, { useEffect } from 'react';
import { Layout } from 'components/partials';
import style from './ClaimThanks.module.scss';

export function ClaimThanks(props) {
	useEffect(() => {
		props.gameComplete();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout>
			<div className={style.wrap}>
				<div className={style.title}>{props.json.title}</div>
				<div className={style.code_title}>{props.json.code_title}</div>
				<div className={style.code}>{props.prizeCode}</div>
				<div className={style.prize_title}>{props.json.prize_title}</div>
				<div className={style.prize}>{props.prizeName}</div>
				<div className={style.body}>{props.json.body}</div>
			</div>
		</Layout>
	);
}
