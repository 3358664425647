import React from 'react';
import { Button } from 'bat-components';
import clsx from 'clsx';

import { Layout } from 'components/partials';
import style from './AgeScreener.module.scss';

export function AgeScreener(props) {
	return (
		<Layout>
			<div className={clsx(style.wrap, style['wrap_' + props.market])}>
				<div className={style.title}>{props.json.title}</div>
				<div className={style.subtitle}>{props.json.subtitle}</div>
				<div className={style.body}>{props.json.body}</div>
				<div className={style.buttonGroup}>
					<Button
						variant="primary"
						size="game"
						className={clsx(style.btn, style.btn1)}
						onClick={props.confirmAge}
						// to={'/play' + window.location.search}
						// analytics={{
						// 	category: 'Age screener',
						// 	action: 'Over Age Button Clicked',
						// 	label: 'User is redirected to Play page'
						// }}
					>
						{props.json.btn_over}
					</Button>
					<Button
						variant="secondary"
						size="game"
						className={clsx(style.btn, style.btn2)}
						to={'/underage' + window.location.search}
						// analytics={{
						// 	category: 'Age screener',
						// 	action: 'Under Age Button Clicked',
						// 	label: 'User is redirected to Under Age page'
						// }}
					>
						{props.json.btn_under}
					</Button>
				</div>
			</div>
		</Layout>
	);
}
