import { connect } from 'react-redux';

import { Result as Component } from './Result';

const mapState = state => ({
	market: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
	win: state.content.getIn(['content', 'pages', 'win']).toJS(),
	lose: state.content.getIn(['content', 'pages', 'lose']).toJS(),
	winner: state.content.getIn(['winner']),
	prizeName: state.content.getIn(['prizeName']),
	landingFlavour: state.content.getIn(['landingFlavour'])
});

const mapDispatch = ({ content: { claim } }) => ({
	claim
});

export const Result = connect(mapState, mapDispatch)(Component);
