import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';

import { RouterTransition, AppWrapper, useQueryParams, useSocket } from 'bat-components';

import {
	AgeScreener,
	ClaimThanks,
	ConnectError,
	Error,
	Invalid,
	Loading,
	MaxPlays,
	Play,
	Result,
	TimeOut,
	UnderAge
	// Claim,
	// SignUp,
	// SignUpThanks,
} from 'components/pages';
import { Blocker, Footer, Game, Header } from 'components/partials';

import style from './app.module.scss';

const devMode = process.env.NODE_ENV === 'development'; // disables game + allows refreshing a particular route

export default function Main() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { listener, state, sendToRoom } = useSocket();
	const { g: gameId } = useQueryParams();
	const { height } = useWindowSize();

	useEffect(() => {
		dispatch.content.setGameId(gameId);
		dispatch.content.setHistory(history);
		dispatch.content.setSocket(sendToRoom);
		dispatch.content.getWinningFlavour();
		if (!devMode) {
			dispatch.content.initialise();
		} else {
			dispatch.content.dev();
		}
	}, [dispatch.content, gameId, history, sendToRoom]);

	useEffect(() => {
		listener('confirmGame', function (response) {
			dispatch.content.confirmGame(response);
		});
		listener('denyGame', function (response) {
			dispatch.content.denyGame(response);
		});
	}, [dispatch.content, listener]);

	useEffect(() => {
		dispatch.content.setSocketState(state);
	}, [dispatch.content, state]);

	// handle resize
	useEffect(() => {
		window.document.documentElement.style.setProperty('--vh', `${height / 100}px`);
		window.document.documentElement.style.setProperty('--vh100', `${height}px`);
	}, [height]);

	return (
		<AppWrapper variant="mobile" app="game" mainClassName={style.main} style={{ height: height }}>
			<div className={style.outer}>
				<div className={style.inner}>
					<Game />
					<Header />
					<RouterTransition>
						<Route path="/" exact component={AgeScreener} />
						<Route path="/error" component={Error} />
						<Route path="/connecterror" component={ConnectError} />
						<Route path="/invalid" component={Invalid} />
						<Route path="/maxplays" component={MaxPlays} />
						<Route path="/underage" component={UnderAge} />
						<Route path="/timeout" component={TimeOut} />
						<Route path="/loading" component={Loading} />
						<Route path="/play" component={Play} />
						<Route path="/result" component={Result} />
						<Route path="/claimthanks" component={ClaimThanks} />
						{/* <Route path="/signup" component={SignUp} />
						<Route path="/signupthanks" component={SignUpThanks} />
						<Route path="/claim" component={Claim} /> */}
					</RouterTransition>
					<Footer />
				</div>
			</div>
			<Blocker />
		</AppWrapper>
	);
}
