import { connect } from 'react-redux';

import { AgeScreener as Component } from './AgeScreener';

const mapState = state => ({
	market: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
	json: state.content.getIn(['content', 'pages', 'agescreener']).toJS()
});

const mapDispatch = ({ content: { confirmAge } }) => ({
	confirmAge
});

export const AgeScreener = connect(mapState, mapDispatch)(Component);
