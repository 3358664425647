import { connect } from 'react-redux';

import { Game as Component } from './Game';

const mapState = state => ({
	// json: state.content.getIn(['content', 'pages', 'agescreener']).toJS()
	market: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
	winner: state.content.getIn(['winner']),
	confirmState: state.content.getIn(['confirmState']),
	playState: state.content.getIn(['playState']),
	isCancelled: state.content.getIn(['isCancelled']),

	flavours: state.content.getIn(['content', 'flavours']).toJS()
});

const mapDispatch = ({ content: { setPlayState, setDragAngle, spinToWin, spinComplete } }) => ({
	setPlayState,
	setDragAngle,
	spinToWin,
	spinComplete
});

export const Game = connect(mapState, mapDispatch)(Component);
