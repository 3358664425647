import { connect } from 'react-redux';

import { UnderAge as Component } from './UnderAge';

const mapState = state => ({
	json: state.content.getIn(['content', 'pages', 'underage']).toJS()
});

const mapDispatch = ({ content: { underAge } }) => ({
	underAge
});

export const UnderAge = connect(mapState, mapDispatch)(Component);
