import { useEffect, useState } from 'react';

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	useEffect(() => {
		function handleResize() {
			// detect device screen size, depending on orientation
			let screenHeight = 0;
			if (window.navigator && !!window.navigator.standalone) {
				if (window.orientation && (window.orientation === 90 || window.orientation === -90)) {
					screenHeight = window.screen.width;
				} else {
					screenHeight = window.screen.height;
				}
			}
			//
			let viewportHeight = 0;
			if (document.documentElement && document.documentElement.clientHeight) {
				viewportHeight = document.documentElement.clientHeight;
			} else {
				viewportHeight = window.innerHeight;
			}
			// save
			setWindowSize({
				width: window.innerWidth,
				height:
					'standalone' in window.navigator && !!window.navigator.standalone
						? screenHeight
						: viewportHeight
			});
		}

		window.addEventListener('resize', handleResize);
		window.addEventListener('orientationchange', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('orientationchange', handleResize);
		};
	}, []);

	return windowSize;
}

export default useWindowSize;
