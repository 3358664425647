import { connect } from 'react-redux';

import { Header as Component } from './Header';

const mapState = state => ({
	market: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
	confirmState: state.content.getIn(['confirmState'])
});

export const Header = connect(mapState)(Component);
