import { connect } from 'react-redux';

import { Footer as Component } from './Footer';

const mapState = state => ({
	common: state.content.getIn(['content', 'pages', 'common']).toJS(),
	terms: state.content.getIn(['content', 'terms']),
	privacy: state.content.getIn(['content', 'privacy']),
	confirmState: state.content.getIn(['confirmState'])
});

export const Footer = connect(mapState)(Component);
