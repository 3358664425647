import React, { useEffect } from 'react';
import { Message } from '../../pages';

export function UnderAge(props) {
	useEffect(() => {
		props.underAge();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Message body={props.json.body}></Message>;
}
