import { Map, fromJS } from 'immutable';
import { apiRequest } from 'bat-components';
// import ReactGA from 'react-ga';

const rand = (minNo, maxNo) => {
	var difference = maxNo - minNo + 1;
	var randomNo = Math.floor(Math.random() * difference);
	return randomNo + minNo;
};

const devMode = process.env.NODE_ENV === 'development'; // dev mode allows playing without a store id / game id

var maxRequestRetries = 3; // 3 - max times to retry failed API calls
// var maxPlays = 3000; // 3 - max plays per day
var timeoutConnect = 1000 * 3; // 3 - how long to wait for a socket connection before erroring
var timeoutDenyGame = 1000 * 3; // 3 - how long to wait for a game confirmation before denying
var timeoutUser = 1000 * 60;

if (devMode) {
	// maxPlays = 3000;
	timeoutUser = 1000 * 4;
}

export const content = {
	state: Map({
		content: null,
		history: null,
		socketSend: null,
		socketState: null,
		confirmState: null,
		playState: null, // loading, animating, calculating, ready, spinning, complete
		gameId: null,
		winner: false,
		prizeName: null,
		prizeCode: null,
		winningFlavour: null,
		landingFlavour: 'Test Flavour',
		denyGameTimeout: null,
		connectTimeout: null,
		cancelGameTimeout: null,
		isCancelled: false,
		showBlocker: false,
		dragAngle: 0
	}),

	reducers: {
		setInitialState(state, payload) {
			return state.setIn(['content'], fromJS(payload));
		},
		setWinningFlavour(state, payload) {
			return state.setIn(['winningFlavour'], payload);
		},
		setLandingFlavour(state, payload) {
			return state.setIn(['landingFlavour'], payload);
		},
		setHistory(state, payload) {
			return state.setIn(['history'], payload);
		},
		setSocket(state, payload) {
			return state.setIn(['socketSend'], payload);
		},
		setSocketState(state, payload) {
			return state.setIn(['socketState'], payload);
		},
		setPlayState(state, payload) {
			// console.log('playState', payload);
			return state.setIn(['playState'], payload);
		},
		setConfirmState(state, payload) {
			return state.setIn(['confirmState'], payload);
		},

		setGameId(state, payload) {
			const id = devMode ? 'devGameId' : payload;

			console.log('gameId', id);
			return state.setIn(['gameId'], id);
		},
		setWinner(state, payload) {
			return state.setIn(['winner'], payload);
		},
		setPrizeName(state, payload) {
			return state.setIn(['prizeName'], payload);
		},
		setPrizeCode(state, payload) {
			return state.setIn(['prizeCode'], payload);
		},
		setDenyGameTimeout(state, payload) {
			return state.setIn(['denyGameTimeout'], payload);
		},
		setConnectTimeout(state, payload) {
			return state.setIn(['connectTimeout'], payload);
		},
		setCancelGameTimeout(state, payload) {
			return state.setIn(['cancelGameTimeout'], payload);
		},
		setShowBlocker(state, payload) {
			return state.setIn(['showBlocker'], payload);
		},
		setDragAngle(state, payload) {
			return state.setIn(['dragAngle'], payload);
		},
		setCancelled(state, payload) {
			return state.setIn(['isCancelled'], payload);
		}
	},

	effects: dispatch => ({
		dev(payload, rootState) {
			dispatch.content.setConfirmState(true);
			dispatch.content.incrementPlayCount();
		},
		testLocalStorage(payload, rootState) {
			// try {
			// 	localStorage.setItem('test', 'test');
			// 	localStorage.removeItem('test');
			// 	return true;
			// } catch (e) {
			// 	return false;
			// }
		},
		initialise(payload, rootState) {
			console.log('%c--- initialise ---', 'color:#3183e0');
			// const history = rootState.content.getIn(['history']);
			// history.push('/agescreener' + window.location.search);
		},
		confirmAge(payload, rootState) {
			console.log('%c--- confirmAge ---', 'color:#3183e0');
			const history = rootState.content.getIn(['history']);
			history.push('/loading' + window.location.search);
			dispatch.content.connectToScreen();
		},
		// validateUser(payload, rootState) {
		// 	dispatch.content.connectToScreen();
		// 	dispatch.content.testLocalStorage().then(res => {
		// 		if (res === true) {
		// 			var today = new Date().toISOString().substring(0, 10);
		// 			var playDate = localStorage.getItem('playDate');
		// 			if (playDate === today) {
		// 				var playCount = localStorage.getItem('playCount');
		// 				if (playCount === null) {
		// 					playCount = 1;
		// 				}
		// 				if (playCount >= maxPlays) {
		// 					const history = rootState.content.getIn(['history']);
		// 					history.push('/maxplays' + window.location.search);
		// 					// ReactGA.event({
		// 					// 	category: 'General',
		// 					// 	action: 'User has already played 3 times'
		// 					// });
		// 				} else {
		// 					dispatch.content.connectToScreen();
		// 				}
		// 			} else {
		// 				dispatch.content.connectToScreen();
		// 			}
		// 		} else {
		// 			dispatch.content.connectToScreen();
		// 		}
		// 	});
		// },
		getWinningFlavour(payload, rootState) {
			const flavours = rootState.content.getIn(['content', 'flavours']).toJS();
			const first = flavours[0].name;
			dispatch.content.setWinningFlavour(first);
		},
		connectToScreen(payload, rootState) {
			const socketState = rootState.content.getIn(['socketState']);
			const socketSend = rootState.content.getIn(['socketSend']);

			dispatch.content.setShowBlocker(true);
			if (socketState === 'joinedRoom') {
				console.log('%c--- connectToScreen ---', 'color:#3183e0');
				socketSend('newGame', {
					storeId: rootState.internal.getIn(['store', 'uuid']),
					gameId: rootState.content.getIn(['gameId'])
				});
				dispatch.content.connectTimeout();
			} else {
				if (typeof payload === 'undefined') {
					payload = 1;
				} else {
					payload++;
				}
				if (payload > 30) {
					dispatch.content.logError({
						message_code: 'error.game.connectToScreen.1',
						message: 'Error connecting to screen'
					});
					dispatch.content.connectError();
				} else {
					setTimeout(function () {
						dispatch.content.connectToScreen(payload);
					}, 200);
				}
			}
		},
		connectTimeout(payload, rootState) {
			console.log('%c--- connectTimeout ---', 'color:#3183e0');
			clearTimeout(rootState.content.getIn(['connectTimeout']));
			dispatch.content.setConnectTimeout(
				setTimeout(function () {
					dispatch.content.connectError();
				}, timeoutConnect)
			);
		},
		connectError(payload, rootState) {
			console.log('%c--- connectError ---', 'color:#3183e0');
			const history = rootState.content.getIn(['history']);
			history.push('/connecterror' + window.location.search);
			dispatch.content.setShowBlocker(false);
			dispatch.content.logError({
				message_code: 'error.game.connectError.1',
				message: 'Error connecting to screen'
			});
			// ReactGA.event({
			// 	category: 'General',
			// 	action: 'Unable to connect to a TV screen'
			// });
		},
		confirmGame(payload, rootState) {
			const history = rootState.content.getIn(['history']);
			const actualStoreId = rootState.internal.getIn(['store', 'uuid']);
			const payloadStoreId = payload.storeId;
			const actualGameId = rootState.content.getIn(['gameId']);
			const payloadGameId = payload.gameId;
			if (
				actualStoreId === payloadStoreId &&
				(actualGameId === payloadGameId || (devMode === true && actualGameId === 'devtest'))
			) {
				console.log('%c--- confirmGame ---', 'color:#3183e0');
				clearTimeout(rootState.content.getIn(['denyGameTimeout']));
				clearTimeout(rootState.content.getIn(['connectTimeout']));
				dispatch.content.cancelGameTimeout();
				dispatch.content.setConfirmState(true);
				dispatch.content.setShowBlocker(false);
				history.push('/play' + window.location.search);
				// ReactGA.event({
				// 	category: 'General',
				// 	action: 'Connected to TV screen'
				// });
			} else {
				dispatch.content.logError({
					message_code: 'error.game.confirmGame.1',
					message: 'QR mismatch on confirmGame'
				});
			}
		},
		denyGame(payload, rootState) {
			const actualStoreId = rootState.internal.getIn(['store', 'uuid']);
			const payloadStoreId = payload.storeId;
			if (actualStoreId === payloadStoreId) {
				dispatch.content.denyGameTimeout();
			} else {
				dispatch.content.logError({
					message_code: 'error.game.denyGame.1',
					message: 'QR mismatch on denyGame'
				});
			}
		},
		denyGameTimeout(payload, rootState) {
			console.log('%c--- denyGameTimeout ---', 'color:#3183e0');
			clearTimeout(rootState.content.getIn(['connectTimeout']));
			clearTimeout(rootState.content.getIn(['denyGameTimeout']));
			dispatch.content.setDenyGameTimeout(
				setTimeout(function () {
					dispatch.content.denyGame2();
				}, timeoutDenyGame)
			);
		},
		denyGame2(payload, rootState) {
			const confirmState = rootState.content.getIn(['confirmState']);
			if (confirmState !== true) {
				console.log('%c--- denyGame ---', 'color:#3183e0');
				dispatch.content.setConfirmState(true);
				dispatch.content.setShowBlocker(false);
				const history = rootState.content.getIn(['history']);
				history.push('/invalid' + window.location.search);
				dispatch.content.logError({
					message_code: 'error.game.denyGame2.1',
					message: 'QR mismatch, TV screen denied connection'
				});
				// ReactGA.event({
				// 	category: 'General',
				// 	action: 'TV screen denied connection, invalid QR code'
				// });
			}
		},
		underAge(payload, rootState) {
			const socketSend = rootState.content.getIn(['socketSend']);

			console.log('%c--- underAge ---', 'color:#3183e0');
			socketSend('quitGame', {
				storeId: rootState.internal.getIn(['store', 'uuid']),
				gameId: rootState.content.getIn(['gameId'])
			});
		},
		prepareGame(payload, rootState) {
			console.log('%c--- prepareGame ---', 'color:#3183e0');
			dispatch.content.cancelGameTimeout();
			dispatch.content.setPlayState('loading');
			dispatch.content.setShowBlocker(true);
			const storeId = rootState.internal.getIn(['store', 'uuid']);
			const gameId = rootState.content.getIn(['gameId']);
			var endpoint = 'spintowin/play';
			if (devMode === true) {
				if (
					window.location.hostname.indexOf('localhost') >= 0 ||
					window.location.hostname.indexOf('192.168') >= 0
				) {
					endpoint = rand(0, 1) === 1 ? 'spintowin/play-win' : 'spintowin/play-lose';
				}
			}
			apiRequest(
				endpoint,
				{
					store: storeId,
					identity: gameId
				},
				response => {
					console.log(response);
					if (
						response !== null &&
						typeof response.status !== 'undefined' &&
						response.status === 'ok'
					) {
						if (response.messages.responseCode === 'winner') {
							dispatch.content.setWinner(true);
							dispatch.content.setPrizeName(response.data.title);
						} else {
							dispatch.content.setWinner(false);
						}
						dispatch.content.cancelGameTimeout();
						dispatch.content.setPlayState('animating');
						dispatch.content.setShowBlocker(false);
						// ReactGA.event({
						// 	category: 'General',
						// 	action: 'API Success - spintowin/play'
						// });
					} else {
						if (typeof payload !== 'number') {
							payload = 1;
						}
						if (payload < maxRequestRetries) {
							payload++;
							setTimeout(function () {
								dispatch.content.prepareGame(payload);
							}, 1000);
						} else {
							clearTimeout(rootState.content.getIn(['cancelGameTimeout']));
							const history = rootState.content.getIn(['history']);
							dispatch.content.setShowBlocker(false);
							history.push('/error' + window.location.search);
							dispatch.content.logError({
								message_code: 'error.game.prepareGame.1',
								message: 'API Error - spintowin/play',
								post_vars: response
							});
							// ReactGA.event({
							// 	category: 'General',
							// 	action: 'API Error - spintowin/play'
							// });
						}
					}
				}
			);
		},
		cancelGameTimeout(payload, rootState) {
			const socketSend = rootState.content.getIn(['socketSend']);

			console.log('%c--- cancelGameTimeout ---', 'color:#3183e0');
			clearTimeout(rootState.content.getIn(['cancelGameTimeout']));
			dispatch.content.setCancelGameTimeout(
				setTimeout(function () {
					dispatch.content.cancelGame();
				}, timeoutUser)
			);
			socketSend('refreshNewGameTimeout', {
				storeId: rootState.internal.getIn(['store', 'uuid']),
				gameId: rootState.content.getIn(['gameId'])
			});
		},
		cancelGame(payload, rootState) {
			console.log('%c--- cancelGame ---', 'color:#3183e0');
			const history = rootState.content.getIn(['history']);
			dispatch.content.setConfirmState(false);
			history.push('/timeout' + window.location.search);
			dispatch.content.setCancelled(true);
			dispatch.content.logError({
				message_code: 'error.game.cancelGame.1',
				message: 'Game timed out due to inactivity'
			});
			// ReactGA.event({
			// 	category: 'General',
			// 	action: 'Game timed out due to inactivity'
			// });
		},
		spinToWin(payload, rootState) {
			const socketSend = rootState.content.getIn(['socketSend']);

			console.log('%c--- spinToWin ---', 'color:#3183e0');
			clearTimeout(rootState.content.getIn(['cancelGameTimeout']));
			const landingFlavour = rootState.content
				.getIn(['content', 'flavours', payload.sector])
				.toJS().name;
			dispatch.content.setLandingFlavour(landingFlavour);
			dispatch.content.incrementPlayCount();
			socketSend('spinToWin', {
				...payload,
				storeId: rootState.internal.getIn(['store', 'uuid']),
				gameId: rootState.content.getIn(['gameId']),
				success: rootState.content.getIn(['winner'])
			});
			// ReactGA.event({
			// 	category: 'General',
			// 	action: 'User spun the wheel'
			// });
		},
		incrementPlayCount(payload, rootState) {
			// console.log('%c--- incrementPlayCount ---', 'color:#3183e0');
			// dispatch.content.testLocalStorage().then(res => {
			// 	if (res === true) {
			// 		var today = new Date().toISOString().substring(0, 10);
			// 		var playDate = localStorage.getItem('playDate');
			// 		var playCount = localStorage.getItem('playCount');
			// 		if (playCount === null || playDate !== today) {
			// 			playCount = 1;
			// 		} else {
			// 			playCount = parseInt(playCount) + 1;
			// 		}
			// 		localStorage.setItem('playDate', today);
			// 		localStorage.setItem('playCount', playCount);
			// 	}
			// });
		},
		spinComplete(payload, rootState) {
			console.log('%c--- spinComplete ---', 'color:#3183e0');
			const history = rootState.content.getIn(['history']);
			history.push('/result' + window.location.search);
		},
		gameComplete(payload, rootState) {
			console.log('%c--- gameComplete ---', 'color:#3183e0');
			dispatch.content.setPlayState('complete');
		},
		signUp(payload, rootState) {
			console.log('%c--- signUp ---', 'color:#3183e0');
			const history = rootState.content.getIn(['history']);
			history.push('/signupthanks' + window.location.search);
		},
		claim(payload, rootState) {
			console.log('%c--- claim ---', 'color:#3183e0');
			dispatch.content.setShowBlocker(true);
			apiRequest(
				'spintowin/assign',
				{
					store: rootState.internal.getIn(['store', 'uuid']),
					identity: rootState.content.getIn(['gameId'])
				},
				response => {
					console.log(response);
					const history = rootState.content.getIn(['history']);
					if (
						response !== null &&
						typeof response.status !== 'undefined' &&
						response.status === 'ok' &&
						response.messages.responseCode === 'assigned'
					) {
						dispatch.content.setPrizeCode(response.data.code);
						dispatch.content.setShowBlocker(false);
						history.push('/claimthanks' + window.location.search);
						// ReactGA.event({
						// 	category: 'General',
						// 	action: 'API Success - spintowin/assign'
						// });
					} else {
						if (typeof payload !== 'number') {
							payload = 1;
						}
						if (payload < maxRequestRetries) {
							payload++;
							setTimeout(function () {
								dispatch.content.claim(payload);
							}, 1000);
						} else {
							dispatch.content.setShowBlocker(false);
							history.push('/error' + window.location.search);
							dispatch.content.logError({
								message_code: 'error.game.claim.1',
								message: 'API Error - /spintowin/assign',
								post_vars: response
							});
							// ReactGA.event({
							// 	category: 'General',
							// 	action: 'API Error - spintowin/assign'
							// });
						}
					}
				}
			);
		},
		logError(payload, rootState) {
			try {
				console.log('%cERROR LOG', 'color:#f00');
				payload.store = rootState.internal.getIn(['store', 'uuid']);
				payload.version = rootState.content.getIn(['content', 'version']);
				console.log(payload);
				apiRequest('log', payload, function () {});
			} catch (e) {
				//
			}
		}
	})
};
