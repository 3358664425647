import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Spinner } from 'components/partials';
import { Draggable } from 'gsap/all';
import style from './Game.module.scss';
import clsx from 'clsx';

import ThrowPropsPlugin from 'libs/ThrowPropsPlugin';

export function Game({
	isCancelled,
	setPlayState,
	spinToWin,
	spinComplete,
	setDragAngle,
	...props
}) {
	// ======================================================================
	// VARS
	// ======================================================================

	const [spinnerState, setSpinnerState] = useState(null);
	const [spinnerVars, setSpinnerVars] = useState(null);

	const draggableRef = useRef();
	const dragRef = useRef();

	const minVelocity = 250;

	useEffect(() => {
		switch (props.playState) {
			case 'animating':
				setSpinnerState('gameReady');
				break;
			case 'complete':
				setSpinnerState('gameComplete');
				break;
			default:
				break;
		}
	}, [props.playState]);

	// ======================================================================
	// SPINNER CALLBACK
	// ======================================================================

	const spinnerCallback = useCallback(
		(method, data) => {
			console.log(method);

			switch (method) {
				case 'returnGameReady':
					setPlayState('ready');
					break;
				case 'returnSpinToWin':
					spinToWin(data);
					break;
				case 'returnSpinComplete':
					spinComplete();
					break;
				default:
					break;
			}
		},
		[setPlayState, spinComplete, spinToWin]
	);

	// ======================================================================
	// SPINNER
	// ======================================================================

	const spinClockwise = useCallback(() => {
		if (props.playState === 'ready') {
			setPlayState('spinning');
			if (props.winner === true) {
				setSpinnerState('spinClockwiseWin');
			} else {
				setSpinnerState('spinClockwiseLose');
			}
		}
	}, [props.playState, props.winner, setPlayState]);

	const spinAnticlockwise = useCallback(() => {
		if (props.playState === 'ready') {
			setPlayState('spinning');
			if (props.winner === true) {
				setSpinnerState('spinAnticlockwiseWin');
			} else {
				setSpinnerState('spinAnticlockwiseLose');
			}
		}
	}, [props.playState, props.winner, setPlayState]);

	const onDrag = useCallback(
		function () {
			const { rotation } = this;
			setSpinnerState('spinToAngle');
			setSpinnerVars(rotation);
			setDragAngle(rotation);
		},
		[setDragAngle]
	);

	const onDragEnd = useCallback(
		function () {
			const velocity = ThrowPropsPlugin.getVelocity(this.target, 'rotation');
			if (velocity >= minVelocity) {
				Draggable.get(draggableRef.current).kill();
				spinClockwise();
			} else if (velocity <= -minVelocity) {
				Draggable.get(draggableRef.current).kill();
				spinAnticlockwise();
			}
		},
		[spinAnticlockwise, spinClockwise]
	);

	useEffect(() => {
		if (props.playState === 'ready' && typeof dragRef.current === 'undefined') {
			dragRef.current = Draggable.create(draggableRef.current, {
				type: 'rotation',
				onDrag: onDrag,
				onDragEnd: onDragEnd,
				onThrowUpdate: onDrag,
				throwProps: true,
				snap: endValue => {
					return 0;
				}
			});
		}
	}, [onDrag, onDragEnd, props.playState]);

	// ======================================================================
	// RENDER
	// ======================================================================

	if (isCancelled) {
		return null;
	}

	return (
		<div className={style.wrapper}>
			<div className={style.game}>
				<div className={style.spinner_wrap}>
					{props?.flavours && (
						<Spinner
							spinnerType="game"
							spinnerData={props?.flavours}
							spinnerCallback={spinnerCallback}
							spinnerState={spinnerState}
							spinnerVars={spinnerVars}
						/>
					)}
				</div>
			</div>
			<div
				className={clsx(style.device, style['device_' + props.market], {
					[style.device_visible]: props.playState === 'ready' || props.playState === 'spinning'
				})}
			></div>

			{props.playState === 'ready' && <div className={style.draggable} ref={draggableRef}></div>}
		</div>
	);
}
