// ======================================================================
// INIT
// ======================================================================

export function init(env, callback) {
	// reset canvas state
	resetState(env, callback);
	// set canvas options
	setOptions();
}

// ======================================================================
// STATE
// ======================================================================

export let state = {};
export function resetState(env, callback = () => {}) {
	state = {
		env: env,
		callback,
		// init
		inited: false,
		// canvas refs
		ctx1: null,
		ctx2: null,
		// sector data
		setupState1: null,
		setupState2: null,
		defaultState1: null,
		defaultState2: null,
		currentState1: null,
		currentState2: null,
		futureState1: null,
		futureState2: null,
		// general
		total_sectors: 0,
		highlight_sector: null,
		canvas_radius: 0,
		spinner_radius: 0,
		// rotation vars
		rotating: false,
		rotation: 0,
		rotation_start_time: null,
		// intervals
		timeouts: {},
		interval_draw: null
	};
}

// ======================================================================
// STATE
// ======================================================================

export function setOptions() {
	if (state.env === 'welcome') {
		options.canvas_diameter = 1800;
		options.spinner1_diameter = 1800;
		options.spinner2_diameter = 1800;
		options.highlight_angle = 41;
		options.highlight_radius_multiplier = 1;
		options.animate_opacity = true;
		options.hide_colors_on_init = true;
		options.hide_colors_on_reset = true;
		options.dark_show = true;
		options.shards_show = true;
		options.stroke_show = false;
		options.middle_show = false;
	} else if (state.env === 'game') {
		options.canvas_diameter = 2100;
		options.spinner1_diameter = 2100;
		options.spinner2_diameter = 700;
		options.highlight_angle = 40;
		options.highlight_angle2 = 52;
		options.highlight_radius_multiplier = 1.05;
		options.animate_opacity = false;
		options.hide_colors_on_init = false;
		options.hide_colors_on_reset = false;
		options.dark_show = true;
		options.shards_show = true;
		options.stroke_show = false;
		options.middle_show = false;
	}
}

export const options = {
	view: null,
	// dimensions
	canvas_diameter: 0,
	spinner1_diameter: 0,
	spinner2_diameter: 0,
	highlight_angle: 60,
	highlight_radius_multiplier: 1.05,
	// behaviour
	animate_opacity: false,
	hide_colors_on_init: false,
	hide_colors_on_reset: false,
	// dark spinner
	dark_show: false,
	// shards
	shards_show: false,
	// stroke
	stroke_show: false,
	stroke_color: '#000',
	stroke_width: 6,
	// middle
	middle_show: false,
	middle: {
		type: 'circle',
		fill: '#000',
		data: {
			radius: 500
		}
	},
	// timings
	time_highlight_transition: 750,
	time_fan_stagger: 100,
	time_fan_transition: 500,
	time_result_delay: 4600,
	time_rotate_spin: 4000,
	time_rotate_slow: 25000,
	time_fade: 750,
	// minimum sector duplications
	minimums: {
		1: 6,
		2: 3,
		3: 2,
		4: 2,
		5: 2
	}
};

// ======================================================================
// SECTORS
// ======================================================================

export const sectors = {
	dark_sectors: [
		{
			type: 'slice',
			fill: {
				'5': '#282928',
				'75': '#121313'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#1f201f',
				'75': '#070809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#282928',
				'75': '#070809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#1c1d1d',
				'75': '#09090a'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#282928',
				'75': '#191a19'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#262827',
				'75': '#070809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#1c1d1d',
				'75': '#09090a'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#191919',
				'75': '#0c0d0e'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#2d2c2b',
				'75': '#080809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#282928',
				'75': '#080809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#181919',
				'75': '#080809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#191a19',
				'75': '#080809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#272727',
				'75': '#1a1b1b'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#1c1c1c',
				'75': '#070809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#282928',
				'75': '#090a0b'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#0f1010',
				'75': '#070809'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#0a0a0b',
				'75': '#09090a'
			}
		},
		{
			type: 'slice',
			fill: {
				'5': '#1b1b1b',
				'75': '#09090a'
			}
		}
	],
	coloured_shards: [
		{
			type: 'slice',
			side: 'left',
			width: 0.2,
			fill: {
				'5': 'rgba(255,255,255,0.2)',
				'60': 'rgba(255,255,255,0.05)'
			}
		},
		null,
		{
			type: 'slice',
			side: 'right',
			width: 0.1,
			fill: {
				'5': 'rgba(255,255,255,0.2)',
				'60': 'rgba(255,255,255,0.05)'
			}
		},
		null,
		null
	],
	dark_shards: [
		{
			type: 'slice',
			side: 'right',
			width: 0.07,
			fill: {
				'5': 'rgba(255,255,255,0.15)',
				'60': 'rgba(255,255,255,0)'
			}
		},
		null,
		{
			type: 'slice',
			side: 'right',
			width: 0.1,
			fill: {
				'5': 'rgba(255,255,255,0.15)',
				'60': 'rgba(255,255,255,0)'
			}
		},
		null,
		{
			type: 'slice',
			side: 'left',
			width: 0.04,
			fill: {
				'5': 'rgba(255,255,255,0)',
				'60': 'rgba(255,255,255,0.15)'
			}
		},
		null,
		{
			type: 'slice',
			side: 'right',
			width: 0.1,
			fill: {
				'5': 'rgba(255,255,255,0)',
				'60': 'rgba(255,255,255,0.06)'
			}
		},
		{
			type: 'slice',
			side: 'right',
			width: 0.22,
			fill: {
				'5': 'rgba(255,255,255,0.02)',
				'60': 'rgba(255,255,255,0.05)'
			}
		},
		null,
		null,
		null,
		{
			type: 'slice',
			side: 'left',
			width: 0.1,
			fill: {
				'5': 'rgba(255,255,255,0.04)',
				'60': 'rgba(255,255,255,0)'
			}
		},
		null,
		null,
		{
			type: 'slice',
			side: 'left',
			width: 0.08,
			fill: {
				'5': 'rgba(255,255,255,0.04)',
				'60': 'rgba(255,255,255,0)'
			}
		},
		null,
		null,
		null
	],
	coloured_triangles: [
		null,
		null,
		null,
		null,
		null,
		null,
		{
			type: 'triangle',
			left: 0.45,
			right: 0.32,
			fill: {
				'5': 'rgba(255,255,255,0.03)',
				'75': 'rgba(255,255,255,0.1)'
			}
		},
		{
			type: 'triangle',
			left: 0.12,
			right: 0.15,
			fill: {
				'5': 'rgba(255,255,255,0.4)',
				'75': 'rgba(255,255,255,0.2)'
			}
		},
		{
			type: 'triangle',
			left: 0.4,
			right: 0.33,
			fill: {
				'5': 'rgba(255,255,255,0.2)',
				'75': 'rgba(255,255,255,0.05)'
			}
		},
		null,
		null,
		{
			type: 'triangle',
			left: 0.4,
			right: 0.6,
			fill: {
				'5': 'rgba(255,255,255,0.2)',
				'75': 'rgba(255,255,255,0.05)'
			}
		},
		null,
		null,
		null,
		null,
		{
			type: 'triangle',
			left: 0.27,
			right: 0.3,
			fill: {
				'5': 'rgba(255,255,255,0.3)',
				'75': 'rgba(255,255,255,0.1)'
			}
		},
		{
			type: 'triangle',
			left: 0.4,
			right: 0.55,
			fill: {
				'5': 'rgba(255,255,255,0.05)',
				'75': 'rgba(255,255,255,0.2)'
			}
		}
	]
};
