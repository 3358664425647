import React from 'react';
import style from './Header.module.scss';

import { ReactComponent as LogoVype } from 'assets/images/logo-vype.svg';
import { ReactComponent as LogoVuse } from 'assets/images/logo-vuse.svg';
import { ReactComponent as LogoVuseGo } from 'assets/images/vusego.svg';

export function Header(props) {
	const Logo = props.market === 'za' ? LogoVuseGo : props.market === 'uk' ? LogoVuse : LogoVype;

	return (
		<header className={style.header}>
			{props.confirmState && (
				<div className={style.logo}>
					<Logo></Logo>
				</div>
			)}
		</header>
	);
}
