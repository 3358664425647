import { connect } from 'react-redux';

import { ClaimThanks as Component } from './ClaimThanks';

const mapState = state => ({
	json: state.content.getIn(['content', 'pages', 'claim_thanks']).toJS(),
	prizeName: state.content.getIn(['prizeName']),
	prizeCode: state.content.getIn(['prizeCode'])
});

const mapDispatch = ({ content: { gameComplete } }) => ({
	gameComplete
});

export const ClaimThanks = connect(mapState, mapDispatch)(Component);
