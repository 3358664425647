import React, { useState, useEffect } from 'react';
import { Button } from 'bat-components';
import { Layout } from 'components/partials';
import style from './Result.module.scss';
import clsx from 'clsx';

export function Result(props) {
	const [visibleState, setVisibleState] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setVisibleState(true);
		}, 750);
	}, []);

	const showForm = false;

	const renderWinButton = () => {
		if (showForm) {
			return (
				<Button
					variant="primary"
					size="game"
					className={clsx(style.btn, style.btnwin)}
					to={'/claim' + window.location.search}
					// analytics={{
					// 	category: 'Winning result page',
					// 	action: 'Claim button clicked',
					// 	label: 'User is redirected to claim form'
					// }}
				>
					{props.win.btn}
				</Button>
			);
		} else {
			return (
				<Button
					variant="primary"
					size="game"
					className={clsx(style.btn, style.btnwin)}
					onClick={props.claim}
					// analytics={{
					// 	category: 'Winning result page',
					// 	action: 'Claim button clicked',
					// 	label: 'User is redirected to claim form'
					// }}
				>
					{props.win.btn}
				</Button>
			);
		}
	};

	const renderLoseButton = () => {
		if (showForm) {
			return (
				<div>
					<div className={style.body}>{props.lose.body}</div>
					<Button
						variant="primary"
						size="game"
						className={clsx(style.btn, style.btnlose)}
						to={'/signup' + window.location.search}
						// analytics={{
						// 	category: 'Lose result page',
						// 	action: 'Sign up button clicked',
						// 	label: 'User is redirected to sign up form'
						// }}
					>
						{props.lose.btn}
					</Button>
				</div>
			);
		}
	};

	const renderFound2 = () => {
		if (typeof props.win.found2 !== 'undefined') {
			return <div className={style.found2}>{props.win.found2}</div>;
		}
	};

	if (props.winner === true) {
		return (
			<Layout>
				<div
					className={clsx(style.wrap, style['wrap_' + props.market], {
						[style.wrap_visible]: visibleState === true
					})}
				>
					<div className={style.found}>{props.win.found}</div>
					<div className={style.flavour}>{props.landingFlavour}</div>
					{renderFound2()}
					<div className={style.title}>{props.win.title}</div>
					<div className={style.prize}>{props.prizeName}</div>
					{renderWinButton()}
				</div>
			</Layout>
		);
	} else {
		return (
			<Layout>
				<div
					className={clsx(style.wrap, style['wrap_' + props.market], {
						[style.wrap_visible]: visibleState === true
					})}
				>
					<div className={style.found}>{props.lose.found}</div>
					<div className={style.flavour}>{props.landingFlavour}</div>
					{renderFound2()}
					<div className={style.title}>{props.lose.title}</div>
					{renderLoseButton()}
				</div>
			</Layout>
		);
	}
}
