import React from 'react';
import style from './Blocker.module.scss';

export function Blocker(props) {
	if (props.showBlocker === true) {
		return <div className={style.blocker}></div>;
	}

	return null;
}
