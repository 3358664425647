import React from 'react';
import clsx from 'clsx';

import { Layout as BatLayout } from 'bat-components';
import style from './Layout.module.scss';

export function Layout(props) {
	return (
		<BatLayout className={clsx(style.layout, style[props.variant])}>{props.children}</BatLayout>
	);
}
