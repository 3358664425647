import React from 'react';
import style from './Footer.module.scss';
// import { getEndpoint } from 'bat-components';

export function Footer(props) {
	if (props.confirmState === true) {
		return (
			<footer className={style.footer}>
				<a className={style.terms} href={props.terms} target="_blank" rel="noopener noreferrer">
					{props.common.terms}
				</a>
				{/* <a
						className={style.privacy}
						href={props.privacy}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.common.privacy}
					</a> */}
			</footer>
		);
	}

	return null;
}
