// ======================================================================
// IMPORTS
// ======================================================================

import { state, options } from './vars';

// ======================================================================
// LOGS
// ======================================================================

const devLog = function () {}; // console.log;

// ======================================================================
// INTERVAL / ANIMATION FRAMES
// ======================================================================

export function canvas_init(canvasRef1, canvasRef2) {
	if (state.interval_draw === null) {
		devLog('%c--- canvas_start ---', 'color:#7405c3;');
		// set canvas dimensions
		canvasRef1.current.width = options.canvas_diameter;
		canvasRef1.current.height = options.canvas_diameter;
		canvasRef2.current.width = options.canvas_diameter;
		canvasRef2.current.height = options.canvas_diameter;
		// save canvas contexts
		state.ctx1 = canvasRef1.current.getContext('2d');
		state.ctx2 = canvasRef2.current.getContext('2d');
		// render initial state
		if (options.dark_show) {
			canvas_render(1);
		}
		canvas_render(2);
		// loop
		clearInterval(state.interval_draw);

		canvas_interval();
	}
}

// function canvas_pause() {
// 	devLog('%c--- canvas_pause ---', 'color:#7405c3;');
// 	clearInterval(state.interval_draw);
// 	state.interval_draw = null;
// }

const RAF = window.requestAnimationFrame;

function canvas_interval() {
	function anim() {
		if (state.env === 'game') {
			canvas_clear(1);
			canvas_render(1);
		}
		canvas_clear(2);
		canvas_render(2);

		RAF(anim);
	}

	RAF(anim);
}

// ======================================================================
// CLEAR CANVAS
// ======================================================================

function canvas_clear(id) {
	var ctx = state['ctx' + id];
	ctx.clearRect(0, 0, options.canvas_diameter, options.canvas_diameter);
}

// ======================================================================
// DRAW ELEMENTS
// ======================================================================

function canvas_render(id) {
	var ctx = state['ctx' + id];
	for (var i in state['currentState' + id]) {
		var element = state['currentState' + id][i];
		// draw element
		if (element.type === 'slice') {
			ctx.beginPath();
			ctx.moveTo(state.canvas_radius, state.canvas_radius);
			ctx.arc(
				state.canvas_radius,
				state.canvas_radius,
				element.data.radius,
				element.data.angle_start,
				element.data.angle_end
			);
			ctx.closePath();
		} else if (element.type === 'triangle') {
			ctx.beginPath();
			ctx.moveTo(state.canvas_radius, state.canvas_radius);
			ctx.lineTo(element.data.p1x, element.data.p1y);
			ctx.lineTo(element.data.p2x, element.data.p2y);
			ctx.lineTo(element.data.p3x, element.data.p3y);
			ctx.closePath();
		} else if (element.type === 'circle') {
			ctx.globalCompositeOperation = 'destination-out';
			ctx.beginPath();
			ctx.arc(state.canvas_radius, state.canvas_radius, element.data.radius, 0, 2 * Math.PI);
			ctx.closePath();
		}
		// fill
		if (typeof element.fill === 'object') {
			ctx.fillStyle = generate_gradient(id, element.fill, element.grad.grad1, element.grad.grad2);
		} else {
			ctx.fillStyle = element.fill;
		}
		ctx.fill();
		// stroke
		if (options.stroke_show) {
			ctx.globalCompositeOperation = 'destination-out';
			ctx.lineWidth = options.stroke_width;
			ctx.strokeStyle = options.stroke_color;
			ctx.stroke();
		}
		// reset global composition
		ctx.globalCompositeOperation = 'source-over';
	}
}

// ======================================================================
// GENERATE GRADIENTS
// ======================================================================

function generate_gradient(id, gradient, p1, p2) {
	// vars
	var ctx = state['ctx' + id];
	var minX = p1[0];
	var maxX = p2[0];
	var minY = p1[1];
	var maxY = p2[1];
	// generate grad
	var grad = ctx.createLinearGradient(minX, minY, maxX, maxY);
	for (var i in gradient) {
		grad.addColorStop(i / 100, gradient[i]);
	}
	return grad;
}
