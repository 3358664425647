import React from 'react';
// import { Layout } from 'bat-components';
import { Layout } from 'components/partials';
import style from './Message.module.scss';

export function Message(props) {
	const renderContent = () => {
		var html = [];
		if (typeof props.title !== 'undefined' && props.title !== '') {
			html.push(
				<div key="1" className={style.title}>
					{props.title}
				</div>
			);
		}
		if (typeof props.body !== 'undefined' && props.body !== '') {
			html.push(
				<div key="2" className={style.body}>
					{props.body}
				</div>
			);
		}
		if (typeof props.footnote !== 'undefined' && props.footnote !== '') {
			html.push(
				<div key="3" className={style.footnote}>
					{props.footnote}
				</div>
			);
		}
		return html;
	};

	return (
		<Layout>
			<div className={style.wrap}>{renderContent()}</div>
		</Layout>
	);
}
