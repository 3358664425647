import React, { useEffect, useState } from 'react';
import { Layout } from 'components/partials';
import style from './Play.module.scss';
import clsx from 'clsx';

export function Play(props) {
	const [visibleState, setVisibleState] = useState(false);

	useEffect(() => {
		props.prepareGame();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.playState === 'animating') {
			setTimeout(() => {
				setVisibleState(true);
				props.cancelGameTimeout();
			}, 1900);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.playState]);

	return (
		<Layout style={{ pointerEvents: 'none' }}>
			<div
				className={clsx(style.wrap, style['wrap_' + props.market], {
					[style.wrap_visible]: visibleState === true && props.playState !== 'spinning'
				})}
			>
				<div className={style.title}>{props.json.title}</div>
				<div className={style.body}>
					{props.json.body.replace('$_flavour', props.winningFlavour)}
				</div>
				<div
					className={clsx(style.flavour, {
						[style.flavour_visible]: Math.abs(props.dragAngle) < 5
					})}
				>
					{props.winningFlavour}
				</div>
			</div>
		</Layout>
	);
}
