// ======================================================================
// IMPORTS
// ======================================================================

import { TweenMax } from 'gsap/TweenMax';
import { state, options } from './vars';
import * as calc from './calculate';
import * as helper from './helpers';

// ======================================================================
// LOGS
// ======================================================================

const devLog = function () {}; //console.log;

// ======================================================================
// RESETS
// ======================================================================

export function ani_reset_all(rotation) {
	devLog('%c--- ani_reset_all ---', 'color:#ee1818;');
	ani_reset_all_timeouts();
	ani_reset_all_tweens();
	// spinner state
	ani_reset_spinner_state(true);
	// highlight
	ani_highlight_none(true);
	// hide sectors
	if (options.hide_colors_on_init) {
		ani_hide_all_sectors(false);
	} else {
		ani_show_all_sectors(false);
	}
	// environment specific setup
	if (state.env === 'welcome') {
		ani_hide_spinner();
	} else if (state.env === 'game') {
		ani_game_reset();
	}
}

function ani_reset_to_spin() {
	devLog('%c--- ani_reset_to_spin ---', 'color:#ee1818;');
	ani_reset_all_timeouts();
	ani_reset_all_tweens();
	// spinner state
	ani_reset_spinner_state(false, state.rotation, false);
	// highlight
	ani_highlight_none();
}

export function ani_reset_all_timeouts() {
	devLog('%c--- ani_reset_all_timeouts ---', 'color:#ee1818;');
	for (var i in state.timeouts) {
		clearTimeout(state.timeouts[i]);
		state.timeouts[i] = null;
	}
}

export function ani_reset_all_tweens() {
	devLog('%c--- ani_reset_all_tweens ---', 'color:#ee1818;');

	// TweenMax.killTweensOf('*');
	TweenMax.killAll(false, true, true);
}

function ani_reset_spinner_state(resetDrop, rotation) {
	if (typeof rotation === 'undefined') {
		rotation = 0;
	}
	// outer
	if (resetDrop) {
		ani_set_drop(false);
	}
	// inner
	ani_set_rotation(rotation);
	// save
	state.rotation = rotation;
}

// ======================================================================
// ELEMENT STYLING
// ======================================================================

export function ani_show_spinner() {
	if (options.animate_opacity) {
		devLog('%c--- ani_show_spinner ---', 'color:#bf218a;');
		var element = document.getElementById('spinner_outer_' + state.env);
		TweenMax.to(element, 1, {
			opacity: 1
		});
	}
}

export function ani_hide_spinner() {
	if (options.animate_opacity) {
		devLog('%c--- ani_hide_spinner ---', 'color:#bf218a;');
		var element = document.getElementById('spinner_outer_' + state.env);
		TweenMax.to(element, 1, {
			opacity: 0
		});
	}
}

function ani_set_drop(drop, duration) {
	devLog('%c--- ani_set_drop - ' + drop + ' ---', 'color:#bf218a;');
	var element = document.getElementById('spinner_outer_' + state.env);
	if (typeof duration === 'undefined') {
		duration = 500;
	}
	if (drop === 'gameReady') {
		state.callback('dropState', 'ready');
		TweenMax.to(element, duration / 1000, {
			top: '-3.2%',
			ease: 'Power2.easeInOut'
		});
	} else if (drop === 'gameComplete') {
		state.callback('dropState', 'complete');
		TweenMax.to(element, duration / 1000, {
			top: '1.65%',
			ease: 'Power2.easeInOut'
		});
	} else if (drop === 'result' && state.env === 'welcome') {
		state.callback('dropState', 'result');
		TweenMax.to(element, 0.5, {
			top: '18.5%',
			ease: 'Power2.easeInOut'
		});
	} else if (drop === 'result' && state.env === 'game') {
		state.callback('dropState', 'result');
		TweenMax.to(element, 0.5, {
			top: '2%',
			ease: 'Power2.easeInOut'
		});
	} else if (drop === false) {
		state.callback('dropState', 'default');
		TweenMax.set(element, {
			top: 0
		});
	}
}

function ani_set_rotation(rotation, duration, ease) {
	devLog('%c--- ani_set_rotation - ' + rotation + ' ---', 'color:#bf218a;');
	var element = document.getElementById('spinner_inner_' + state.env);
	if (typeof duration === 'undefined') {
		duration = 0;
	}
	if (typeof ease === 'undefined') {
		ease = 'Power2.easeInOut';
	}
	TweenMax.to(element, duration / 1000, {
		rotation: rotation,
		ease: ease
	});
}

var ani_rotation_element;

export function ani_set_rotation_instant(rotation) {
	devLog('%c--- ani_set_rotation_instant - ' + rotation + ' ---', 'color:#bf218a;');
	ani_rotation_element =
		ani_rotation_element || document.getElementById('spinner_inner_' + state.env);

	TweenMax.set(ani_rotation_element, {
		rotation: rotation
	});
}

// ======================================================================
// SET SPINNER RADIUS
// ======================================================================

function ani_set_radius(radius) {
	devLog('%c--- ani_set_radius - ' + radius + ' ---', 'color:#bf218a;');
	if (state.env === 'game' && radius === 'gameReady') {
		state.spinner2_radius = (options.spinner2_diameter / 2) * 0.82;
		calc.calculate_updates();
	} else if (state.env === 'game' && radius === 'gameResult') {
		state.spinner2_radius = (options.spinner2_diameter / 2) * 3;
		calc.calculate_updates();
	} else if (state.env === 'game' && radius === 'gameComplete') {
		state.spinner2_radius = (options.spinner2_diameter / 2) * 0.31;
		calc.calculate_updates();
	}
}

// ======================================================================
// SHOW / HIDE SECTOR EVENTS
// ======================================================================

// INSTANT
export function ani_show_all_sectors_instant() {
	devLog('%c--- ani_show_all_sectors_instant ---', 'color:#f075bb;');
	var animate = false;
	var stagger_time = 0;
	ani_reset_all_tweens();
	ani_show_all_sectors(animate, stagger_time);
}
export function ani_hide_all_sectors_instant() {
	devLog('%c--- ani_hide_all_sectors_instant ---', 'color:#f075bb;');
	var animate = false;
	var stagger_time = 0;
	ani_reset_all_tweens();
	ani_hide_all_sectors(animate, stagger_time);
}

// ANIMATED
export function ani_show_all_sectors_animate() {
	devLog('%c--- ani_show_all_sectors_animate ---', 'color:#f075bb;');
	var animate = true;
	var stagger_time = 0;
	ani_reset_all_tweens();
	ani_show_all_sectors(animate, stagger_time);
}
export function ani_hide_all_sectors_animate() {
	devLog('%c--- ani_hide_all_sectors_animate ---', 'color:#f075bb;');
	var animate = true;
	var stagger_time = 0;
	ani_reset_all_tweens();
	ani_hide_all_sectors(animate, stagger_time);
}

// ANIMATED AND STAGGERED
export function ani_show_all_sectors_stagger() {
	devLog('%c--- ani_show_all_sectors_stagger ---', 'color:#f075bb;');
	var animate = true;
	var stagger_time = options.time_fan_stagger / 1000;
	ani_reset_all_tweens();
	ani_show_all_sectors(animate, stagger_time);
	ani_rotate_to_end();
}
export function ani_hide_all_sectors_stagger() {
	devLog('%c--- ani_hide_all_sectors_stagger ---', 'color:#f075bb;');
	var animate = true;
	var stagger_time = options.time_fan_stagger / 1000;
	ani_reset_all_tweens();
	ani_hide_all_sectors(animate, stagger_time);
	ani_rotate_to_end();
}

// ======================================================================
// SHOW / HIDE ALL SECTORS
// ======================================================================

function ani_show_all_sectors(animate, stagger, reset) {
	var total = state.total_sectors;
	var delay = 0;
	for (var i = 0; i < total; i++) {
		delay += stagger;
		var transitionTime = options.time_fan_transition;
		ani_show_sector(i, animate, transitionTime, delay);
	}
}

function ani_hide_all_sectors(animate, stagger) {
	var total = state.total_sectors;
	var delay = stagger * total;
	for (var i = 0; i < total; i++) {
		delay -= stagger;
		var transitionTime = options.time_fan_transition;
		ani_hide_sector(i, animate, transitionTime, delay);
	}
}

// ======================================================================
// SHOW / HIDE INDIVIDUAL SECTOR
// ======================================================================

function ani_show_sector(key, animate, transitionTime, delay) {
	devLog('%c--- ani_show_sector ---', 'color:#f075bb;');
	for (const i in state.currentState2) {
		const currentState = state.currentState2[i];
		if (currentState.key === key) {
			const defaultState = state.defaultState2[i];
			const defaultData = defaultState.data;
			const oldData = currentState.data;

			let newData = {
				...defaultData,
				radius: typeof currentState.data.radius !== 'undefined' ? state.spinner2_radius : undefined
			};

			if (animate) {
				newData = {
					...newData,
					delay: delay,
					ease: 'Power2.easeInOut'
				};

				TweenMax.to(oldData, transitionTime / 1000, newData);
			} else {
				currentState.data = newData;
			}
		}
	}
}

function ani_hide_sector(key, animate, transitionTime, delay) {
	devLog('%c--- ani_hide_sector ---', 'color:#f075bb;');
	for (const i in state.currentState2) {
		const currentState = state.currentState2[i];
		if (currentState.key === key) {
			const oldData = currentState.data;
			const newData = helper.clone(oldData);

			if (currentState.type === 'slice') {
				newData.angle_end = newData.angle_start;
			} else if (currentState.type === 'triangle') {
				newData.p3x = oldData.p2x;
				newData.p3y = oldData.p2y;
			}

			if (animate) {
				newData.delay = delay;
				newData.ease = 'Power2.easeInOut';
				TweenMax.to(oldData, transitionTime / 1000, newData);
			} else {
				currentState.data = newData;
			}
		}
	}
}

// ======================================================================
// HIGHLIGHT (EXPAND) SECTOR
// ======================================================================

function ani_highlight_sector(key, drop, delay) {
	devLog('%c--- ani_highlight_sector - ' + key + ' ---', 'color:#f075bb;');
	if (delay) {
		state.timeouts.ani_highlight_sector = setTimeout(function () {
			ani_set_radius('gameResult');
			ani_highlight_sector2(key, drop);
		}, options.time_result_delay);
	} else {
		ani_highlight_sector2(key, drop);
	}
}

function ani_highlight_sector2(key, drop) {
	state.highlight_sector = key;
	calc.calculate_updates(false, state.env === 'game' && drop === 'result' ? true : false);
	if (drop) {
		ani_set_drop(drop);
	}
}

function ani_highlight_none(instant) {
	state.highlight_sector = null;
	calc.calculate_updates(instant);
}

// ======================================================================
// WELCOME TV - ROTATE CANVAS
// ======================================================================

function ani_rotate() {
	devLog('%c--- ani_rotate ---', 'color:#f075bb;');
	var current_rotation = state.rotation;
	var new_rotation = current_rotation + 720;
	state.rotating = true;
	state.rotation = new_rotation;
	state.rotation_start_time = new Date();
	ani_show_spinner();
	ani_set_rotation(new_rotation, options.time_rotate_slow, 'Power0.easeNone');
	state.timeouts.rotate1 = setTimeout(function () {
		ani_hide_spinner();
	}, options.time_rotate_slow - options.time_fade);
	state.timeouts.rotate2 = setTimeout(function () {
		ani_rotate_pause();
	}, options.time_rotate_slow);
}

function ani_rotate_pause() {
	state.rotating = false;
}

function ani_rotate_to_end() {
	if (state.rotating) {
		devLog('%c--- ani_rotate_to_end ---', 'color:#ee1818;');
		ani_reset_all_timeouts();
		ani_rotate_to_end2();
		state.rotating = false;
	}
}

function ani_rotate_to_end2() {
	// calculate elapsed rotation time
	var currentTime = new Date();
	var startTime = state.rotation_start_time;
	var elapsedTime = currentTime - startTime;
	var elapsedDecimal = elapsedTime / options.time_rotate_slow;
	// calculate angle rotated in degrees
	var fullRotation = 720;
	var elapsedRotation = fullRotation * elapsedDecimal;
	var remainingRotation = 720 - elapsedRotation;
	// change remaining rotation if necessary (don't rotate too much / too little)
	var minimumRotation = 135;
	if (remainingRotation < minimumRotation) {
		state.rotation = state.rotation + 360;
	} else if (remainingRotation - 360 > minimumRotation) {
		state.rotation = state.rotation - 360;
	}
	// calculate duration
	var duration = state.total_sectors * options.time_fan_stagger + options.time_fan_transition;
	// apply rotation animation
	ani_set_rotation(state.rotation, duration, 'Power2.easeOut');
}

// ======================================================================
// WELCOME TV - SECTOR ANIMATION
// ======================================================================

export function ani_animate_sectors(callback) {
	devLog('%c--- ani_animate_sectors ---', 'color:#f075bb;');
	ani_reset_all();
	state.timeouts.ani_animate_sectors = setTimeout(function () {
		ani_hide_all_sectors_instant();
		ani_rotate();
		// animation variables
		var increment = 400;
		var show_delay = 0;
		var hide_delay = 0;
		var hide_multiplier = 7;
		var delay_multiplier = 5;
		// array of elements to animate
		var elements = [
			1,
			7,
			13,
			null,
			4,
			11,
			17,
			null,
			6,
			14,
			null,
			10,
			16,
			3,
			null,
			14,
			2,
			8,
			null,
			17,
			5,
			12,
			null,
			6,
			17
		];
		// create animation
		for (var i in elements) {
			if (elements[i] != null) {
				show_delay += increment;
				hide_delay = show_delay + increment * hide_multiplier;
				ani_animate_sectors2(i, elements[i], show_delay, hide_delay);
			} else {
				show_delay += increment * delay_multiplier;
				hide_delay = show_delay + increment * hide_multiplier * delay_multiplier;
			}
		}
		// callback
		if (typeof callback === 'function') {
			state.timeouts.ani_animate_sectors2 = setTimeout(function () {
				callback('returnAnimateComplete');
			}, options.time_rotate_slow);
		}
	}, 20);
}

function ani_animate_sectors2(i, key, show_delay, hide_delay) {
	state.timeouts['animate-' + i + '-a'] = setTimeout(function () {
		ani_show_sector(key, true, 1000);
	}, show_delay);
	state.timeouts['animate-' + i + '-b'] = setTimeout(function () {
		ani_hide_sector(key, true, 1000);
	}, hide_delay);
}

// ======================================================================
// WELCOME TV - READY / CANCEL
// ======================================================================

export function ani_welcome_ready() {
	devLog('%c--- ani_welcome_ready ---', 'color:#f075bb;');
	if (!state.rotating) {
		ani_hide_all_sectors_instant();
		ani_reset_all();
	}
	ani_show_all_sectors_stagger();
	ani_show_spinner();
	var duration = state.total_sectors * options.time_fan_stagger + options.time_fan_transition;
	state.timeouts.ani_welcome_ready = setTimeout(function () {
		ani_show_spinner();
		ani_highlight_sector(0, false, false);
	}, duration + 300);
}

export function ani_welcome_cancel() {
	devLog('%c--- ani_welcome_cancel ---', 'color:#f075bb;');
	ani_hide_all_sectors_stagger();
	var duration = state.total_sectors * options.time_fan_stagger + options.time_fan_transition;
	state.timeouts.ani_welcome_cancel = setTimeout(function () {
		ani_hide_spinner();
	}, duration - 500);
}

// ======================================================================
// WELCOME TV - SPIN TO WIN
// ======================================================================

export function ani_spin(direction, win) {
	devLog(
		'%c--- ani_spin - ' + direction + ' - ' + (win ? 'win' : 'lose') + ' ---',
		'color:#f075bb;'
	);

	// reset
	// ani_reset_to_spin();
	// ani_highlight_none();

	// vars
	var current_rotation = state.rotation;
	var total_sectors = state.total_sectors;

	// calculate angle of one sector
	var sector_angle = 360 / total_sectors;

	// randomly determine a number of sections to rotate by
	var random_spin = helper.rand(total_sectors * 3, total_sectors * 4);

	// calculate angle to rotate by
	var to_rotate = sector_angle * random_spin;

	// calculate the new angle to rotate to
	var new_rotation =
		direction === 'clockwise' ? current_rotation + to_rotate : current_rotation - to_rotate;

	// if lose, ensure lands on losing sector
	if (!win && new_rotation % 360 === 0) {
		new_rotation = new_rotation + helper.rand(1, 6) * sector_angle;
	}

	// if win, ensure lands on winning sector
	if (win && new_rotation % 360 !== 0) {
		if (direction === 'clockwise') {
			// if ani_spinning clockwise, increase
			let correction_sectors = total_sectors - (new_rotation % 360) / sector_angle;
			let correction_angle = correction_sectors * sector_angle;
			new_rotation = new_rotation + correction_angle;
		} else {
			// if ani_spinning anticlockwise, decrease
			let correction_sectors = (new_rotation % 360) / sector_angle;
			let correction_angle = correction_sectors * sector_angle;
			new_rotation = new_rotation - correction_angle;
		}
	}

	// determine which sector was landed on
	var landing_sector = total_sectors - ((new_rotation / sector_angle) % total_sectors);
	if (landing_sector >= total_sectors) {
		// negative new_rotation
		landing_sector = landing_sector - total_sectors;
	}

	// return
	return {
		sector: landing_sector,
		rotation: new_rotation
	};
}

export function ani_spin_to(spinData, callback) {
	devLog('%c--- ani_spin_to ---', 'color:#f075bb;');
	// reset
	ani_reset_to_spin();
	ani_highlight_none();
	// save rotation
	// if (state.env === 'welcome') {
	// 	var new_rotation = state.rotation - (state.rotation % 360) + spinData.rotation;
	// }
	state.rotation = spinData.rotation;
	// apply rotation
	ani_set_rotation(state.rotation, options.time_rotate_spin, 'Power2.easeOut');
	// highlight sector
	ani_highlight_sector(spinData.sector, 'result', true);
	// callback
	if (typeof callback === 'function') {
		state.timeouts.ani_spin_to = setTimeout(function () {
			callback('returnSpinComplete');
		}, options.time_rotate_spin + 100);
	}
}

// ======================================================================
// GAME - RESET / READY
// ======================================================================

export function ani_game_reset() {
	devLog('%c--- ani_game_reset ---', 'color:#f075bb;');
	// hide central sectors
	ani_hide_sector(state.total_sectors - 2);
	ani_hide_sector(state.total_sectors - 1);
	ani_hide_sector(0);
	ani_hide_sector(1);
	// spin to centre
	var center = 360 - 360 / (state.total_sectors * 2);
	ani_set_rotation(-center);
	// ani_set_rotation(-350);
}

export function ani_game_ready(callback) {
	devLog('%c--- ani_game_ready ---', 'color:#f075bb;');
	var duration = 2000;
	// rotate to start
	ani_set_rotation(0, duration);
	// animate appearance
	ani_set_drop('gameReady', duration);
	ani_set_radius('gameReady');
	// show hidden sectors
	state.timeouts.ani_game_ready1 = setTimeout(function () {
		ani_show_all_sectors(false, 0);
	}, duration * 0.5);
	// expand primary sector
	state.timeouts.ani_game_ready2 = setTimeout(function () {
		ani_highlight_sector(0, false, false);
	}, duration);
	// callback
	state.timeouts.ani_game_ready3 = setTimeout(function () {
		callback('returnGameReady');
	}, duration + options.time_highlight_transition);
}

export function ani_game_complete() {
	devLog('%c--- ani_game_complete ---', 'color:#f075bb;');
	ani_highlight_none(false);
	ani_set_drop('gameComplete');
	ani_set_radius('gameComplete');
}

// ======================================================================
// TEST - SPIN TO ANGLE
// ======================================================================

// export function ani_snap_to_angle(angle) {
// 	devLog('%c--- ani_snap_to_angle - ' + angle + ' ---', 'color:#f075bb;');
// 	// transpose angle to between 0 and 360
// 	var limitedAngle = angle % 360;
// 	if (limitedAngle < 0) {
// 		limitedAngle = limitedAngle + 360;
// 	}
// 	// calculate remainder angle
// 	var remainder = angle - limitedAngle;
// 	// find the element currently pointing right
// 	var key = ani_find_element_at_angle(limitedAngle);
// 	// snap to the sector
// 	ani_snap_to_sector(key, remainder);
// 	// return
// 	return key;
// }

// function ani_find_element_at_angle(angle) {
// 	// convert the rotation angle
// 	var convertedAngle = ani_rotation_angle_to_sector_angle(angle);
// 	// convert angle to radians
// 	var radiansAngle = helper.toRadians(convertedAngle);
// 	// search for sector that faces this angle
// 	var result = null;
// 	for (var i in state.currentState2) {
// 		var element = state.currentState2[i];
// 		var type = element.type;
// 		if (type === 'slice') {
// 			var angle_start = element.data.angle_start;
// 			var angle_end = element.data.angle_end;
// 			if (angle_start <= radiansAngle && angle_end >= radiansAngle) {
// 				result = element.key;
// 				break;
// 			}
// 		}
// 	}
// 	// return
// 	return result;
// }

// function ani_rotation_angle_to_sector_angle(angle) {
// 	// calculate angle of spinner that points right
// 	var searchAngle = -(angle - 90);
// 	if (searchAngle < 0) {
// 		searchAngle = searchAngle + 360;
// 	}
// 	if (searchAngle >= 360) {
// 		searchAngle = searchAngle - 360;
// 	}
// 	// account for offset of canvas elements
// 	var offsetAngle = 90;
// 	var adjustedAngle = searchAngle - offsetAngle;
// 	// account for angles larger than maximum sector angle
// 	var maxAngle = helper.toDegrees(state.currentState2[state.total_sectors - 1].data.angle_end);
// 	if (adjustedAngle > maxAngle) {
// 		adjustedAngle = adjustedAngle - 360;
// 	}
// 	// return
// 	return adjustedAngle;
// }

// function ani_snap_to_sector(key, remainder, instant) {
// 	devLog('%c--- ani_snap_to_sector - ' + key + ' ---', 'color:#f075bb;');
// 	// highlight the sector - subsequent rotation depends on this
// 	ani_highlight_sector(key);
// 	// find the midpoint angle of highlighted sector
// 	for (var i in state.futureState2) {
// 		var element = state.futureState2[i];
// 		if (element.key === key) {
// 			var angle_start = element.data.angle_start;
// 			var angle_end = element.data.angle_end;
// 			var angle_mid = angle_start + (angle_end - angle_start) / 2;
// 			break;
// 		}
// 	}
// 	// round the angle
// 	var newAngle = Math.round(helper.toDegrees(angle_mid) * 1000) / 1000;
// 	// calculate the spinner rotation angle
// 	var rotationAngle = 360 - newAngle;
// 	if (rotationAngle < 0) {
// 		rotationAngle = rotationAngle + 360;
// 	}
// 	if (rotationAngle > 360) {
// 		rotationAngle = rotationAngle - 360;
// 	}
// 	rotationAngle = rotationAngle + remainder;
// 	// calculate duration
// 	var rotationDifference = Math.abs(state.rotation - rotationAngle);
// 	var duration = instant ? 0 : ani_get_spin_duration(rotationDifference);
// 	// save and apply
// 	state.rotation = rotationAngle;
// 	ani_set_rotation(rotationAngle, duration);
// }

// function ani_get_spin_duration(angle) {
// 	var duration_min = 500;
// 	var duration_max = 2500;
// 	var duration_dif = duration_max - duration_min;
// 	var angle_min = 0;
// 	var angle_max = 5000;
// 	var angle_dif = angle_max - angle_min;
// 	var angle_step = duration_dif / angle_dif;
// 	var new_duration = duration_min + angle_step * angle;
// 	return new_duration;
// }
