import { connect } from 'react-redux';

import { Play as Component } from './Play';

const mapState = state => ({
	market: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
	json: state.content.getIn(['content', 'pages', 'play']).toJS(),
	winningFlavour: state.content.getIn(['winningFlavour']),
	playState: state.content.getIn(['playState']),
	dragAngle: state.content.getIn(['dragAngle'])
});

const mapDispatch = ({ content: { prepareGame, cancelGameTimeout } }) => ({
	prepareGame,
	cancelGameTimeout
});

export const Play = connect(mapState, mapDispatch)(Component);
